<template>
  <div>
    <b-table
      hover
      :busy="fetchingRecords"
      :items="deals"
      :fields="tableFields"
      :per-page="perPage"
      :current-page="currentPage"
      class="table-light"
      thead-class="active-products-table-head shadow-sm"
      tbody-class="active-products-table-body"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mx-4"></b-spinner>
          <strong>Fetching...</strong>
        </div>
      </template>
      <template v-slot:cell(products)="data">
        {{ data.value.length }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { GET_ALL_DEALS } from "@/core/services/store/deals.module";
import { mapState } from "vuex";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      tableFields: [
        {
          key: "dealName",
          label: "Name",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "dealId",
          label: "Deal Id",
          sortable: true
        },
        {
          key: "products",
          label: "Products",
          sortable: true
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(GET_ALL_DEALS, {
      offset: this.currentPage - 1,
      limit: this.perPage
    });
  },
  methods: {
    updateDeals: function() {
      console.log("updateDeals");
      this.$store.dispatch(GET_ALL_DEALS, {
        offset: this.currentPage - 1,
        limit: this.perPage
      });
    }
  },
  computed: {
    ...mapState({
      fetchingRecords: state => state.deals.dealsFetching,
      deals: state => state.deals.allDeals,
      rows: state => state.deals.totalDeals
    })
  }
};
</script>

<style lang="scss" scoped>
::v-deep .b-table {
  .active-products-table-head {
    tr {
      th {
        color: #763fb8;
        width: 8.75%;
        font-size: 11px;
        vertical-align: top;
        width: 7.14%;
        &:nth-child(6) {
          width: 20%;
        }
        &:nth-child(7) {
          width: 15%;
        }
        &:nth-child(10) {
          width: 15%;
        }
      }
    }
  }
  .active-products-table-body {
    tr {
      td {
        vertical-align: middle;
        font-size: 12px;
        .action-cell {
          width: 100%;
          display: flex;
          .action-btn {
            width: 75px;
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            margin-right: 5px;
            color: #fff;
            cursor: pointer;
            border-radius: 15px;
            &.edit {
              background: #4ff86f;
            }
            &.archive {
              background: #f8b040;
            }
          }
        }
        .info-rating-block {
          display: flex;
          flex-direction: column;
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            // font-weight: 600;
            .ratings-block {
              i {
                font-size: 10px;
              }
              i.gold {
                color: #ff9500;
              }
            }
          }
        }
      }
    }
  }
}
</style>
